
import { defineComponent } from 'vue';
import ProjectItemComponent from "@/components/ProjectItemComponent.vue";
import { ProjectItem } from "@/models/ProjectItem";
import {Image} from "@/models/Image";
import {Credit} from "@/models/Credit";

type FilterType = "ALL" | "GEBOORTE" | "HUWELIJK" | "BRANDING";

export default defineComponent({
  name: 'Projects',
  components: {
    ProjectItemComponent
  },
  data(){
    return {
      projects: [] as ProjectItem[],
      filteredProjects: [] as ProjectItem[],
      activeFilters: {
        ALL: true,
        GEBOORTE: false,
        HUWELIJK: false,
        BRANDING: false
      } as Record<FilterType, boolean>,
      filtersOpen: false as boolean
    }
  },  
  methods: {
    getActiveFilter(): string {
      if(this.activeFilters.GEBOORTE){return "GEBOORTE"}
      if(this.activeFilters.HUWELIJK){return "HUWELIJK"}
      if(this.activeFilters.BRANDING){return "BRANDING"}

      return "ALLE"
    },
    openCloseFilters(): void {
      this.filtersOpen = !this.filtersOpen;
    },
    filter(filterCategory: FilterType){
      switch(filterCategory){
        case "GEBOORTE": this.activeFilters.GEBOORTE = true; this.activeFilters.ALL = false; this.activeFilters.HUWELIJK = false; this.activeFilters.BRANDING = false; break;
        case "HUWELIJK": this.activeFilters.GEBOORTE = false; this.activeFilters.ALL = false; this.activeFilters.HUWELIJK = true; this.activeFilters.BRANDING = false; break;
        case "BRANDING": this.activeFilters.GEBOORTE = false; this.activeFilters.ALL = false; this.activeFilters.HUWELIJK = false; this.activeFilters.BRANDING = true; break;
        default: this.activeFilters.GEBOORTE = false; this.activeFilters.ALL = true; this.activeFilters.HUWELIJK = false; this.activeFilters.BRANDING = false; break;
      }
      
      if(filterCategory === "ALL")
      {
        this.filteredProjects = this.projects;
        return;
      }        
      this.filteredProjects = this.projects.filter((project) => project.category === filterCategory);
    },
    
    fillProjects(){
      
      //branding
      const allurImages = require.context('../assets/projects/branding/Branding_ALLUR/', false);
      const rodenbachImages = require.context('../assets/projects/branding/Branding_APOTHEEK RODENBACH/', false);
      const fullonImages = require.context('../assets/projects/branding/Branding_FULL ON/', false);
      const ivoorImages = require.context('../assets/projects/branding/Branding_IVOOR/', false);
      const papetteImages = require.context('../assets/projects/branding/Branding_PAPETTE/', false);
      const powerupImages = require.context('../assets/projects/branding/Branding_POWER UP/', false);
      const raiseTheBarImages = require.context('../assets/projects/branding/Branding_RAISE THE BAR/', false);
      const sieronImages = require.context('../assets/projects/branding/Branding_SIERON/', false);
      const vrImages = require.context('../assets/projects/branding/Branding_VR GAMES AT HOME/', false);
      const beerImages = require.context('../assets/projects/branding/Branding_BUSINESS BEER/', false);
      
      //geboorte
      const auroreImages = require.context('../assets/projects/geboorte/Geboorte_AURORE/', false);
      const elodieImages = require.context('../assets/projects/geboorte/Geboorte_ELODIE/', false);
      const estherImages = require.context('../assets/projects/geboorte/Geboorte_ESTHER/', false);
      const lilyImages = require.context('../assets/projects/geboorte/Geboorte_LILY/', false);
      const jakobImages = require.context('../assets/projects/geboorte/Geboorte_JAKOB/', false);
      const lisanneimages = require.context('../assets/projects/geboorte/Geboorte_LISANNE/', false);
      const matteoImages = require.context('../assets/projects/geboorte/Geboorte_MATTEO/', false);
      const reneeImages = require.context('../assets/projects/geboorte/Geboorte_RENEE/', false);

      //huwelijk
      const anjaArnaudImages = require.context('../assets/projects/huwelijk/Huwelijk_ANJA & ARNAUD/', false);
      const annaMarceloImages = require.context('../assets/projects/huwelijk/Huwelijk_ANNA & MARCELO/', false);
      const hannahKyleImages = require.context( '../assets/projects/huwelijk/Huwelijk_HANNAH & KYLE/', false);
      const magaliYorickImages = require.context( '../assets/projects/huwelijk/Huwelijk_MAGALI & YORICK/', false);
      
      this.projects = [
        {
          id: 1,
          name:"Aurore Foulon",
          category: "GEBOORTE",
          thumbnail: { id: 1, path: auroreImages('./geboortekaart-op-maat-Aurore-meisje-goudfolie-foliedruk-drukwerk-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: auroreImages('./geboortekaart-op-maat-Aurore-meisje-goudfolie-foliedruk-drukwerk-1.jpg')
            } as Image,
            {
              id: 2, path: auroreImages('./geboortekaart-op-maat-Aurore-meisje-goudfolie-foliedruk-drukwerk-2.jpg')
            } as Image,
            {
              id: 3, path: auroreImages('./geboortekaart-op-maat-Aurore-meisje-goudfolie-foliedruk-drukwerk-3.jpg')
            } as Image,
          ] as Image[],
          story: ['Afgeronde hoekjes en glanzende goudfolie voor deze mooie tekening.'],
          technique: 'Foliedruk, Offset, Afgeronde hoeken',
          drawing: 'Aurore’s nonkel Bart'
        } as ProjectItem,
        {
          id: 14,
          name:"Apotheek Rodenbach",
          category: "BRANDING",
          thumbnail: { id: 1, path: rodenbachImages('./branding-logo-huisstijl-apotheek-rodenbach-machelen-zulte-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: rodenbachImages('./branding-logo-huisstijl-apotheek-rodenbach-machelen-zulte-1.jpg')
            } as Image,
            {
              id: 2, path: rodenbachImages('./branding-logo-huisstijl-social-media-templates-instagram-apotheek-rodenbach-machelen-zulte-2.jpg')
            } as Image,
            {
              id: 3, path: rodenbachImages('./branding-logo-huisstijl-kleurenpallet-apotheek-rodenbach-machelen-zulte-3.jpg')
            } as Image,
            {
              id: 4, path: rodenbachImages('./branding-logo-huisstijl-apotheek-rodenbach-machelen-zulte-4.jpg')
            } as Image,
          ] as Image[],
          story: ['Hoge herkenbaarheid en toegankelijkheid waren twee grote pijlers voor de rebranding van Apotheek Rodenbach te Zulte. Bij Apotheek Rodenbach staat vragen echt vrij, hun klanten adviseren op de best mogelijke manier dragen zij hoog in het vaandel. Ze kozen voor deze frisse en toegankelijke rebranding. Duidelijk, kleurrijk en zacht, zo voelt iedereen zich welkom en in goede handen.'],
          customer: 'Apotheek',
          byCarte: 'Logo, Huisstijl, E-mailbanner, Social media templates'
        } as ProjectItem,
        {
          id: 5,
          name:"Lily Vandamme",
          category: "GEBOORTE",
          thumbnail: { id: 1, path: lilyImages('./geboortekaart-op-maat-Lily-meisje-letterpress-roze-debossing-digitaal-drukwerk-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: lilyImages('./geboortekaart-op-maat-Lily-meisje-letterpress-roze-debossing-digitaal-drukwerk-1.jpg')
            } as Image,
            {
              id: 2, path: lilyImages('./geboortekaart-op-maat-Lily-meisje-letterpress-roze-debossing-digitaal-drukwerk-2.jpg')
            } as Image,
            {
              id: 3, path: lilyImages('./geboortekaart-op-maat-Lily-meisje-letterpress-roze-debossing-digitaal-drukwerk-3.jpg')
            } as Image,
            {
              id: 4, path: lilyImages('./geboortekaart-op-maat-Lily-meisje-letterpress-roze-debossing-digitaal-drukwerk-4.jpg')
            } as Image
          ] as Image[],
          story: ['Bloemetjes en roze voor Lily.'],
          technique: 'Letterpress, Offset',
        } as ProjectItem,
        {
          id: 21,
          name:"VR Games At Home",
          category: "BRANDING",
          thumbnail: { id: 1, path: vrImages('./professioneel-logo-vr-games-at-home-nazareth-oculus-gaming-thuislevering-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: vrImages('./professioneel-logo-vr-games-at-home-nazareth-oculus-gaming-thuislevering-1.jpg')
            } as Image,
            {
              id: 2, path: vrImages('./professioneel-logo-vr-games-at-home-nazareth-oculus-gaming-thuislevering-2.jpg')
            } as Image,
            {
              id: 3, path: vrImages('./professioneel-flyer-ontwerp-vr-games-at-home-nazareth-oculus-gaming-thuislevering-3.jpg')
            } as Image,
          ] as Image[],
          story: ['VR Games at Home, een innovatief verhuurbedrijf dat zich richt op het aanbieden van virtual reality-brillen voor de ultieme gamingervaring. De branding kreeg een modern en speels karakter, met een fris kleurpalet. Wist je dat de mannen van VR Games at Home hun virtual reality brillen bij jou thuis komen leveren? Avontuur en spelplezier gewoon comfortabel en gezellig bij jou thuis.'],
          customer: 'Verhuur van virtual reality brillen',
          byCarte: 'Logo, Huisstijl, Visitekaartjes, Cadeaubonnen, Flyer'
        } as ProjectItem,
        {
          id: 7,
          name:"Matteo Nachtergaele",
          category: "GEBOORTE",
          thumbnail: { id: 1, path: matteoImages('./geboortekaart-op-maat-Matteo-jongen-koperfolie-foliedruk-digitaal-drukwerk-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: matteoImages('./geboortekaart-op-maat-Matteo-jongen-koperfolie-foliedruk-digitaal-drukwerk-1.jpg')
            } as Image,
            {
              id: 2, path: matteoImages('./geboortekaart-op-maat-Matteo-jongen-koperfolie-foliedruk-digitaal-drukwerk-2.jpg')
            } as Image,
            {
              id: 3, path: matteoImages('./geboortekaart-op-maat-Matteo-jongen-koperfolie-foliedruk-digitaal-drukwerk-doopsuiker-3.jpg')
            } as Image
          ] as Image[],
          story: ['Matteo vliegt de wereld in, en dat doet ie goed. Koperfolie voor deze stoere kerel.'],
          technique: 'Foliedruk, Digitaal ',
        } as ProjectItem,
        {
          id: 8,
          name:"Reneé Van Steendam",
          category: "GEBOORTE",
          thumbnail: { id: 1, path: reneeImages('./geboortekaart-op-maat-Renee-meisje-goudfolie-emboss-preeg-crush-papier-corn-drukwerk-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: reneeImages('./geboortekaart-op-maat-Renee-meisje-goudfolie-emboss-preeg-crush-papier-corn-drukwerk-1.jpg')
            } as Image,
            {
              id: 2, path: reneeImages('./geboortekaart-op-maat-Renee-meisje-goudfolie-emboss-preeg-crush-papier-corn-drukwerk-2.jpg')
            } as Image,
            {
              id: 3, path: reneeImages('./geboortekaart-op-maat-Renee-meisje-doopsuiker-geboorte-3.jpg')
            } as Image,
            {
              id: 4, path: reneeImages('./geboortekaart-op-maat-Renee-meisje-goudfolie-emboss-preeg-crush-papier-corn-drukwerk-4.jpg')
            } as Image,
            {
              id: 5, path: reneeImages('./geboortekaart-op-maat-Renee-meisje-doopsuiker-geboorte-5.jpg')
            } as Image,
          ] as Image[],
          story: ['Alleen maar liefde voor de geboortekaart en doopsuikertjes van Reneé.'],
          technique: 'Blinddruk, Offset, Foliedruk',
        } as ProjectItem,
        {
          id: 9,
          name:"Anja & Arnaud",
          category: "HUWELIJK",
          thumbnail: { id: 1, path: anjaArnaudImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-anja-arnaud-goudfolie-foliedruk-letterpress-debossing-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: anjaArnaudImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-anja-arnaud-goudfolie-foliedruk-letterpress-debossing-1.jpg')
            } as Image,
            {
              id: 2, path: anjaArnaudImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-anja-arnaud-goudfolie-foliedruk-letterpress-debossing-2.jpg')
            } as Image,
            {
              id: 3, path: anjaArnaudImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-anja-arnaud-goudfolie-foliedruk-letterpress-debossing-3.jpg')
            } as Image,
            {
              id: 4, path: anjaArnaudImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-anja-arnaud-goudfolie-foliedruk-letterpress-debossing-4.jpg')
            } as Image
          ] as Image[],
          story: ['Winter wedding voor Anja & Arnaud. Stevige kaart afgewerkt met donkergroene letterpress en glanzende foliedruk in combinatie met een diepgroene enveloppe en bijkaartje.'],
          technique: 'Foliedruk, Letterpress, Witprint'
        } as ProjectItem,
        {
          id: 20,
          name:"Sieron - Rise Above Your Body",
          category: "BRANDING",
          thumbnail: { id: 1, path: sieronImages('./branding-logo-huisstijl-sieron-rise-above-your-body-kinesitherapie-brakel-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: sieronImages('./branding-logo-huisstijl-sieron-rise-above-your-body-kinesitherapie-brakel-1.jpg')
            } as Image,
            {
              id: 2, path: sieronImages('./branding-logo-huisstijl-sieron-rise-above-your-body-kinesitherapie-brakel-2.jpg')
            } as Image,
            {
              id: 3, path: sieronImages('./branding-logo-huisstijl-social-media-templates-instagram-sieron-Kinesitherapie-brakel-3.jpg')
            } as Image,
          ] as Image[],
          story: ['Boordevol ideeën en passie voor zijn vak stampte Miguel, SIERON - Rise Above Your Body, uit de grond. Een totaalconcept waarbij een persoonlijke aanpak centraal staat, gericht op preventie van klachten. Carte Confituur ontwierp een krachtig logo met bijhorende branding. Sterke kleurencombinaties, beeldmerk en een strak logo gaven SIERON letterlijk kleur en vorm.'],
          customer: 'Kinesitherapeut - Manuele therapie - Massage - Dry Needling – Cupping',
          byCarte: 'Logo, Huisstijl, Social media templates'
        } as ProjectItem,
        {
          id: 4,
          name:"Jakob Foulon",
          category: "GEBOORTE",
          thumbnail: { id: 1, path: jakobImages('./geboortekaart-op-maat-Jakob-jongen-foliedruk-afgeronde-hoeken-aquarel-drukwerk-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: jakobImages('./geboortekaart-op-maat-Jakob-jongen-foliedruk-afgeronde-hoeken-aquarel-drukwerk-1.jpg')
            } as Image,
            {
              id: 2, path: jakobImages('./geboortekaart-op-maat-Jakob-jongen-foliedruk-afgeronde-hoeken-aquarel-drukwerk-2.jpg')
            } as Image,
            {
              id: 3, path: jakobImages('./geboortekaart-op-maat-Jakob-jongen-foliedruk-afgeronde-hoeken-aquarel-drukwerk-3.jpg')
            } as Image
          ] as Image[],
          story: ['Afgeronde hoekjes en glanzende rode folie voor Jakob. Zo vormt zijn geboortekaartje een mooie verhaal met dat van grote zus Aurore.'],
          technique: 'Foliedruk, Offset, Afgeronde hoeken',
          drawing: 'Jakob’s peter Bart'
        } as ProjectItem,
        {
          id: 10,
          name:"Anna & Marcelo",
          category: "HUWELIJK",
          thumbnail: { id: 1, path: annaMarceloImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-anna-marcelo-goudfolie-foliedruk-italian-wedding-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: annaMarceloImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-anna-marcelo-goudfolie-foliedruk-italian-wedding-1.jpg')
            } as Image,
            {
              id: 2, path: annaMarceloImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-anna-marcelo-goudfolie-foliedruk-italian-wedding-2.jpg')
            } as Image,
            {
              id: 3, path: annaMarceloImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-anna-marcelo-goudfolie-foliedruk-italian-wedding-3.jpg')
            } as Image,
          ] as Image[],
          story: ['Trouwen in het zonnige Italië, dan gaan we voor classy. Initialen-logo en een cute klein citroentje in goudfolie voor Anna & Marcelo.'],
          technique: 'Foliedruk, Offset'
        } as ProjectItem,

        {
          id: 11,
          name:"Hannah & Kyle",
          category: "HUWELIJK",
          thumbnail: { id: 1, path: hannahKyleImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-hannah-kyle-preeg-emboss-origami-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: hannahKyleImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-hannah-kyle-preeg-emboss-origami-1.jpg')
            } as Image,
            {
              id: 2, path: hannahKyleImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-hannah-kyle-preeg-emboss-origami-2.jpg')
            } as Image,
            {
              id: 3, path: hannahKyleImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-hannah-kyle-preeg-emboss-origami-3.jpg')
            } as Image,
          ] as Image[],
          story: ['Als save the date vouwden Hannah & Kyle rode origami hartjes, dus trokken we dit door tot op de huwelijksuitnodigingen (cause I love a good graphic love story). '],
          technique: 'Blinddruk, Digitaal'
        } as ProjectItem,
        {
          id: 2,
          name:"Elodie Cloet",
          category: "GEBOORTE",
          thumbnail: { id: 1, path: elodieImages('./geboortekaart-op-maat-Elodie-meisje-letterpress-roze-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: elodieImages('./geboortekaart-op-maat-Elodie-meisje-letterpress-roze-1.jpg')
            } as Image,
            {
              id: 2, path: elodieImages('./geboortekaart-op-maat-Elodie-meisje-letterpress-roze-2.jpg')
            } as Image,
            {
              id: 3, path: elodieImages('./geboortekaart-op-maat-Elodie-meisje-letterpress-roze-3.jpg')
            } as Image
          ] as Image[],
          story: ['Pretty in pink voor Elodie.'],
          technique: 'Letterpress',
        } as ProjectItem,
        {
          id: 16,
          name:"Ivoor - Luxury Eyewear",
          category: "BRANDING",
          thumbnail: { id: 1, path: ivoorImages('./branding-logo-huisstijl-ivoor-brillenmerk-verpakking-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: ivoorImages('./branding-logo-huisstijl-ivoor-brillenmerk-verpakking-1.jpg')
            } as Image,
            {
              id: 2, path: ivoorImages('./branding-logo-huisstijl-ivoor-brillenmerk-verpakking-2.jpg')
            } as Image,
            {
              id: 3, path: ivoorImages('./branding-logo-huisstijl-ivoor-brillenmerk-verpakking-3.jpg')
            } as Image,
            {
              id: 4, path: ivoorImages('./branding-logo-huisstijl-ivoor-brillenmerk-verpakking-4.jpg')
            } as Image,
          ] as Image[],
          story: ['Van Ivoor kreeg Carte Confituur de vraag om een elegant, toch ‘straight forward’ logo met bijhorende huisstijl en verpakkingen uit de grond te stampen. Exclusiviteit, elegantie en herkenbaarheid waren voor hun drie belangrijke pijlers. Hun lijn van exclusieve luxe zonnebrillen, elk met een eigen karakter, verdiende ook een logo met de nodige ‘ballen’. Door te kiezen voor warme en zachte tinten kreeg deze branding een toegankelijk en dromerig jasje.'],
          customer: 'Brillenmerk',
          byCarte: 'Logo, Huisstijl, Visitekaartjes, Cadeaubonnen, Stickers, Raambelettering, Social media templates'
        } as ProjectItem,
        {
          id: 12,
          name:"Magali & Yorick",
          category: "HUWELIJK",
          thumbnail: { id: 1, path: magaliYorickImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-magali-yorick-goudfolie-foliedruk-digitaal-crush-corn-kiwi-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: magaliYorickImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-magali-yorick-goudfolie-foliedruk-digitaal-crush-corn-kiwi-1.jpg')
            } as Image,
            {
              id: 2, path: magaliYorickImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-magali-yorick-goudfolie-foliedruk-digitaal-crush-corn-kiwi-2.jpg')
            } as Image,
            {
              id: 3, path: magaliYorickImages('./huwelijksdrukwerk-drukwerk-annonce-trouwen-magali-yorick-goudfolie-foliedruk-digitaal-crush-corn-kiwi-3.jpg')
            } as Image,
          ] as Image[],
          story: ['Deze lovebirds kozen voor foliedruk in witgoud op het mooie Crush papier en dat in twee kleurtjes, Kiwi en Corn.'],
          technique: 'Foliedruk, Offset'
        } as ProjectItem,

          //BRANDING
        {
          id: 13,
          name:"Allur by Steffi",
          category: "BRANDING",
          thumbnail: { id: 1, path: allurImages('./professioneel-logo-kapsalon-extensions-hairdresser-allur-by-steffi-vermeersch-olsene-haar-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: allurImages('./professioneel-logo-kapsalon-extensions-hairdresser-allur-by-steffi-vermeersch-olsene-haar-1.png')
            } as Image,
            {
              id: 2, path: allurImages('./professioneel-visitekaartjes-kapsalon-extensions-hairdresser-allur-by-steffi-vermeersch-olsene-haar-2.jpg')
            } as Image,
            {
              id: 3, path: allurImages('./professioneel-logo-kapsalon-extensions-hairdresser-allur-by-steffi-vermeersch-olsene-haar-3.png')
            } as Image,
            {
              id: 4, path: allurImages('./professioneel-cadeaubon-gift-card--kapsalon-extensions-hairdresser-allur-by-steffi-vermeersch-olsene-haar-4.jpg')
            } as Image,
            {
              id: 5, path: allurImages('./belettering-signalisatie-sticker-kapsalon-extensions-hairdresser-allur-by-steffi-vermeersch-olsene-haar-5.jpg')
            } as Image,
            {
              id: 6, path: allurImages('./social-media-templates-kapsalon-extensions-hairdresser-allur-by-steffi-vermeersch-olsene-haar-6.jpg')
            } as Image,
          ] as Image[],
          story: [
            'Allur by Steffi straalt elegantie en verfijning uit, met een moderne branding die de luxe en persoonlijke aandacht weerspiegelt die Steffi schenkt aan iedere klant die in haar kappersstoel komt zitten. Het strakke logo symboliseert zelfvertrouwen, terwijl de warme kleuren een gezellige en uitnodigende sfeer creëren',
          ],
          customer: 'Kapsalon - Make-up - Extensions',
          byCarte: 'Logo, Huisstijl, Visitekaartjes, Cadeaubonnen, Stickers, Raambelettering, Social media templates'
        } as ProjectItem,
        
        {
          id: 15,
          name:"Full On - Your Full Event & Festival Crew Partner",
          category: "BRANDING",
          thumbnail: { id: 1, path: fullonImages('./branding-logo-huisstijl-full-on-eventsector-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: fullonImages('./branding-logo-huisstijl-full-on-eventsector-1.jpg')
            } as Image,
            {
              id: 2, path: fullonImages('./branding-logo-tshirt-textiel-huisstijl-full-on-eventsector-2.jpg')
            } as Image,
            {
              id: 3, path: fullonImages('./branding-logo-huisstijl-full-on-eventsector-3.jpg')
            } as Image,
            {
              id: 4, path: fullonImages('./branding-logo-belettering-carwrap-huisstijl-full-on-eventsector-4.jpg')
            } as Image,
            {
              id: 5, path: fullonImages('./branding-logo-huisstijl-full-on-toegangspass-5.jpg')
            } as Image,
          ] as Image[],
          story: ['Full On verzorgt crew op evenementen en festivals, van heftruckchauffeurs tot licht- en geluidstechniekers. Bij een bonte groep stagehands hoort dan ook een logo met ballen. Felle kleur verlopen en een herkenbaar beeldmerk waren hier dan ook volledig op hun plaats. Het beeldmerk is een combinatie van de F(ull) en de O(n) en bij het logo staat de ‘ON’ op een verhoogje om zo de actie weer te geven. '],
          customer: 'Overall event support - Catering crew - Hospitality crew – S-tagehands',
          byCarte: 'Logo, Huisstijl'
        } as ProjectItem,
        
        {
          id: 17,
          name:"Papette x Carte Confituur",
          category: "BRANDING",
          thumbnail: { id: 1, path: papetteImages('./wenskaarten-kerstkaarten-kerst-papette-quotes-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: papetteImages('./wenskaarten-kerstkaarten-kerst-papette-quotes-1.jpg')
            } as Image,
            {
              id: 2, path: papetteImages('./wenskaarten-kerstkaarten-kerst-papette-quotes-2.jpg')
            } as Image,
            {
              id: 3, path: papetteImages('./wenskaarten-kerstkaarten-kerst-papette-quotes-3.jpg')
            } as Image,
            {
              id: 4, path: papetteImages('./wenskaarten-kerstkaarten-kerst-papette-quotes-4.jpg')
            } as Image,
            {
              id: 5, path: papetteImages('./wenskaarten-kerstkaarten-kerst-papette-quotes-5.jpg')
            } as Image,
          ] as Image[],
          story: ['Kerstkaartjes in alle geuren en kleuren voor Papette, gedrukt door Drukkerij Dirix. '],
          customer: 'Wenskaarten – Drukkerij',
          byCarte: 'Kerstkaarten'
        } as ProjectItem,
        {
          id: 3,
          name:"Esther Roets",
          category: "GEBOORTE",
          thumbnail: { id: 1, path: estherImages('./geboortekaart-op-maat-Esther-aquarel-watercolor-tekening-meisje-digitaal-drukwerk-doopsuiker-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: estherImages('./geboortekaart-op-maat-Esther-aquarel-watercolor-tekening-meisje-digitaal-drukwerk-doopsuiker-1.jpg')
            } as Image,
            {
              id: 2, path: estherImages('./geboortekaart-op-maat-Esther-aquarel-watercolor-tekening-meisje-digitaal-drukwerk-doopsuiker-2.jpg')
            } as Image
          ] as Image[],
          story: ['Een mooie tekening door oma en pops of color door Carte Confituur voor Esther.'],
          technique: 'Offset',
          drawing: 'Esther\'s oma'
        } as ProjectItem,
        {
          id: 18,
          name:"Power Up",
          category: "BRANDING",
          thumbnail: { id: 1, path: powerupImages('./professioneel-logo-personal-trainer-coaching-power-up-drongen-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: powerupImages('./professioneel-logo-personal-trainer-coaching-power-up-drongen-1.jpg')
            } as Image,
            {
              id: 2, path: powerupImages('./professioneel-logo-personal-trainer-coaching-power-up-drongen-2.jpg')
            } as Image,
            {
              id: 3, path: powerupImages('./professioneel-logo-personal-trainer-coaching-power-up-drongen-3.jpg')
            } as Image,
            {
              id: 4, path: powerupImages('./professioneel-logo-personal-trainer-coaching-power-up-drongen-4.jpg')
            } as Image,
          ] as Image[],
          credits: [
          {
            text: "Mockup psd created by freepik - www.freepik.com",
            url: "https://www.freepik.com/psd/mockup"
          } as Credit
          ] as Credit[],
          story: ['Mensen begeleiden naar een sportiever en gezonder leven, daar gaat Power Up 100% voor. Voor de branding van Power Up, een fitnesscoach gericht op kinderen, tieners en volwassenen, werd een dynamisch en energiek logo ontworpen. '],
          customer: 'Personal trainer en fitness coach',
          byCarte: 'Logo, Huisstijl, Visitekaartjes'
        } as ProjectItem,
        {
          id: 19,
          name:"Raise The Bar",
          category: "BRANDING",
          thumbnail: { id: 1, path: raiseTheBarImages('./professioneel-logo-personal-trainer-coaching-raise-the-bar-oudenaarde-nutrition-coach-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: raiseTheBarImages('./professioneel-logo-personal-trainer-coaching-raise-the-bar-oudenaarde-nutrition-coach-1.jpg')
            } as Image,
            {
              id: 2, path: raiseTheBarImages('./professioneel-logo-personal-trainer-coaching-raise-the-bar-oudenaarde-nutrition-coach-2.jpg')
            } as Image,
            {
              id: 3, path: raiseTheBarImages('./professioneel-logo-personal-trainer-coaching-raise-the-bar-oudenaarde-nutrition-coach-3.png')
            } as Image,
          ] as Image[],
          story: ['Met heel veel enthousiasme en toewijding helpt Jasper van Raise The Bar mannelijke levensgenieters hun fitnessdoelen te bereiken zonder saai dieet of onmogelijke trainingsschema’s. Carte Confituur ontwierp een krachtig en doelgericht logo, met een moderne uitstraling die aansluit bij de missie van RTB: het ondersteunen van een actieve levensstijl door middel van effectieve training en voeding. Met deze sterke visuele identiteit helpt Raise The Bar zijn klanten om vol vertrouwen hun grenzen te verleggen en resultaten te behalen.'],
          customer: 'Online voedings- en fit coach',
          byCarte: 'Logo, Huisstijl, Social media templates'
        } as ProjectItem,
        {
          id: 22,
          name:"Business Beer",
          category: "BRANDING",
          thumbnail: { id: 1, path: beerImages('./professioneel-etiket-bierfles-quadruppel-blond-business-beer-nederland-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: beerImages('./professioneel-etiket-bierfles-quadruppel-blond-business-beer-nederland-1.jpg')
            } as Image,
          ] as Image[],
          story: ['Business Beer vroeg me om een bierlabel te ontwerpen voor Hoeveslagerij Hogeslag-Olst. Ik gaf hun bestaande huisstijl een strakke twist en verzorgde de labels voor zowel het blonde bier als voor de Quadruppel.'],
          customer: 'Bierbrouwerij',
          byCarte: 'Bieretiketten'
        } as ProjectItem,
        {
          id: 6,
          name:"Lisanne Bruyneel",
          category: "GEBOORTE",
          thumbnail: { id: 1, path: lisanneimages('./geboortekaart-op-maat-Lisanne-meisje-letterpress-groen-debossing-goudfolie-foliedruk-bosdieren-drukwerk-thumbnail.png')} as Image,
          images: [
            {
              id: 1, path: lisanneimages('./geboortekaart-op-maat-Lisanne-meisje-letterpress-groen-debossing-goudfolie-foliedruk-bosdieren-drukwerk-1.jpg')
            } as Image,
            {
              id: 2, path: lisanneimages('./geboortekaart-op-maat-Lisanne-meisje-letterpress-groen-debossing-goudfolie-foliedruk-bosdieren-drukwerk-2.jpg')
            } as Image,
            {
              id: 3, path: lisanneimages('./geboortekaart-op-maat-Lisanne-meisje-letterpress-groen-debossing-goudfolie-foliedruk-bosdieren-drukwerk-3.jpg')
            } as Image,
            {
              id: 4, path: lisanneimages('./geboortekaart-op-maat-Lisanne-meisje-letterpress-groen-debossing-goudfolie-foliedruk-bosdieren-drukwerk-4.jpg')
            } as Image
          ] as Image[],
          story: ['Het bos op een kaartje voor Lisanne. Afgewerkt met een diepgroene kleur op snee.'],
          technique: 'Foliedruk, Letterpress, Edge painting ',
        } as ProjectItem,
      ] as ProjectItem[]
    }
  },
  mounted(){
    window.scrollTo(0, 0);
    this.fillProjects();
    const passedFilter = this.$route.params.filter as FilterType;
    if(passedFilter){
      this.filter(passedFilter);
    }
    else {
      this.filter('ALL');  
    }
  }
});
